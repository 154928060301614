import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
// import ReactGA from "react-ga4";

export default function WhatsAppButton({
  sala = "en la escuela",
  fechaFuncion = "fecha a definir",
  titleFuncion,
  text = "Button",
  page = "/page",
  className,
  id,
}) {
  const handleClick = (consulta, title) => {
    function reemplazarEspaciosConPorcentaje20(cadena) {
      return cadena.replace(/ /g, "%20");
    }
    const fechaWhatsApp = reemplazarEspaciosConPorcentaje20(consulta);
    const titleWhatsApp = reemplazarEspaciosConPorcentaje20(title);

    window.open(
      `https://wa.me/${process.env.REACT_APP_WHATEL}/?text=Quiero%20reservar%20"${titleWhatsApp}"%20para%20el%20${fechaWhatsApp}%20en%20${sala}`,
      "_blank"
    );

    // ReactGA.send({ hitType: "click", page, title: `WhatsApp BTN ${titleFuncion}, ${fechaFuncion}` });
  };

  return (
    <Button
      variant='primary'
      style={{ width: "100%" }}
      onClick={() => handleClick(fechaFuncion, titleFuncion)}
      className={className}
      id={id}>
      <FontAwesomeIcon icon={faWhatsapp} size='2xl' />
      <br />
      {text}
    </Button>
  );
}
