import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import moment from "moment/moment";
import { FuncionRow } from "../ObrasPorMes/FuncionRow";

export const ProximasFunciones = ({
  obra = "Obra seleccionada",
  funciones,
}) => {
  const [tieneFunciones, setTieneFunciones] = useState(false);
  const [cantidadaDeFunciones, setCantidadaDeFunciones] = useState(0);
  // Utiliza Moment.js para analizar la fecha
  const obtenerFechaFormateada = (fechaString) => {
    const fechaMoment = moment(fechaString, "D [de] MMMM [de] YYYY", "es");

    return fechaMoment;
  };

  const ordenarPorPropiedad = (array, ascendente = true) => {
    // Copia el array original para evitar modificarlo directamente
    const copiaArray = [...array];
    copiaArray.sort((a, b) => {
      const fechaA = a.fechaFin;
      const fechaB = b.fechaFin;

      if (ascendente) {
        return moment(fechaA).isBefore(moment(fechaB)) ? -1 : 1;
      } else {
        return moment(fechaB).isBefore(moment(fechaA)) ? 1 : 1;
      }
    });

    return copiaArray;
  };

  const [funcionesEncontradas, setFuncionesEncontradas] = useState([]);

  function contarApariciones(array, searchString) {
    let count = 0;
    const nuevasFunciones = [];
    // Recorre el array de objetos
    array.forEach((obj) => {
      // Verifica si la propiedad "ID" es de tipo string y contiene el string deseado
      if (typeof obj.ID === "string" && obj.ID.includes(searchString)) {
        const fechaFuncion = obtenerFechaFormateada(obj.fechaFuncion);
        if (
          moment(fechaFuncion)
            .startOf("day")
            .isSameOrAfter(moment().startOf("day"))
        ) {
          count++;

          nuevasFunciones.push(obj);

          ordenarPorPropiedad(nuevasFunciones, false);

          setFuncionesEncontradas(nuevasFunciones);
        }
      }
    });

    return count;
  }

  useEffect(() => {
    setCantidadaDeFunciones(contarApariciones(funciones, obra));

    if (cantidadaDeFunciones > 0) {
      setTieneFunciones(true);
    } else {
      setTieneFunciones(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funciones, cantidadaDeFunciones, obra]);

  return (
    <>
      {tieneFunciones ? (
        <Col className={`mt-5`}>
          <h4>
            {funcionesEncontradas.length === 1
              ? "Próxima función"
              : "Próximas funciones"}
          </h4>
          <hr />
          <Col>
            {Array.from(funcionesEncontradas).map((data, id) =>
              data.ID === obra ? (
                <FuncionRow key={id} data={data} item={data} id={id} />
              ) : (
                ""
              )
            )}
          </Col>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};
